<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="oyauto">
            <table border="1" cellspacing="0" class="tableBorder assistrecordTable" v-loading="loading">
                <thead>
                    <tr class="h60  baf7fafa">
                        <td class="textc itemTitle mw100">序号</td>
                        <td class="textc itemTitle mw400">项目</td>
                        <td class="textc itemTitle mw100">预算金额</td>
                        <td class="textc itemTitle mw100">实际金额</td>
                        <td class="textc itemTitle mw230">研发起止时间</td>
                        <td class="textc itemTitle mw100">项目经理</td>
                        <td class="textc itemTitle mw400">项目组成员</td>
                    </tr>
                </thead>
                <tbody v-if="tableData.list && tableData.list.length > 0">
                    <tr class="h40" v-for="(row, index) in tableData.list" :key="index">
                        <td class="textc itemContent">{{ row.projectNo }}</td>
                        <td class="itemContent">{{ row.projectName }}</td>
                        <td class="textc itemContent">
                            <el-input type="number" class="textc" placeholder="预算" v-model="formData[index].budget"
                                @change="updateMoey($event, index)"></el-input>
                            <!-- {{ row.money }} -->
                        </td>
                        <td class="textc itemContent">{{ row.researchMoney | setMoney }}</td>
                        <td class="textc itemContent">{{ row.startDay + " 至 " + row.endDay }}</td>
                        <td class="textc itemContent">{{ row.headName ? row.headName : "-" }}</td>
                        <td class="itemContent">{{ row.projectUser }}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr class="h200 textc">
                        <td colspan="7">暂无数据</td>
                    </tr>
                </tbody>
                <tfoot v-if="tableData.total" class=" baf7fafa">
                    <tr class="h40 fw600">
                        <td class="textc itemContent">-</td>
                        <td class="textc itemContent">合 计</td>
                        <td class="textc itemContent"> {{ tableData.total.money }}</td>
                        <td class="textc itemContent"> {{ tableData.total.totalResearchMoney | setMoney }}</td>
                        <td class="textc itemContent">-</td>
                        <td class="textc itemContent">-</td>
                        <td class="textc itemContent">-</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import { setProjectData } from "@/api/projectAdmin";
import { getProjectMoneyCountList } from "@/api/projectMoney";
export default {
    name: "assistrecordTable1",
    props: {
        checkedCompany: {
            require: true,
            type: Object,
            default: () => {
                return {}
            }
        },
        yearValue: {
            require: true,
            type: String,
            default: "",
        }
    },
    data() {
        return {
            //列表配置
            loading: false, //表格加载
            showModel: false,
            tableData: {},
            formData: [],
        };
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        }
    },
    watch: {
        checkedCompany: {
            handler(data) {
                //获取年份
               //console.log(data);
               //console.log("checkedCompany");
                this.getList();
            }
        },
        yearValue: {
            handler(data) {
                //获取年份
               //console.log(data);
               //console.log("yearValue");
                this.getList();
            },
            deep: true,
            immediate: true,
        }
    },
    created() {

    },
    methods: {
        updateMoey(val,i) {
            let params={
                ...this.formData[i]
            };
            setProjectData(params).then(res=>{
                if(res.code==200){
                    this.$message.success("预算已更新")
                    this.getList();
                }
            })
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
           //console.log(this.yearValue);
            let params = {
                year: this.yearValue
            };
            if (!this.checkedCompany.pcId) {
                this.tableData = {};
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            try {
                this.loading = true;
                let result = await getProjectMoneyCountList(params);
                this.loading = false;
                const { data } = result;
                let tableData = [];
                let list = [];
                for (let row of data.projectDataList) {
                    let projectUser = [];
                    if (row.projectUserList) {
                        for (let user of row.projectUserList) {
                            projectUser.push(user.userName);
                        }
                    }
                    let data = {
                        ...row,
                        projectUser: projectUser.join("、")
                    }
                    tableData.push(data);
                    list.push({
                        pdId:data.pdId,
                        budget: data.budget ? data.budget : 0,
                        head: data.head,
                        proposer: data.proposer,
                        test: data.test,
                        members: data.members,
                        moneyFrom: data.moneyFrom,
                        projectFrom: data.projectFrom,
                    });
                }
                this.tableData.list = tableData;
                this.formData = list;
                this.tableData.total = data.total;
            } catch (error) {
               //console.log(error);
                this.loading = false;
                this.tableData = {};
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.assistrecordTable {
    border-color: #EBEEF5;

    .itemTitle {
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 16px;
        padding: 5px 10px;
    }
}
</style>