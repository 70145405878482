<template>
    <div class="view">

        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" @click="showAddModel" :disabled="!checkedCompany.pcId || true">导出项目工时表</el-button> -->
            </div>
        </div>
        <div class="oyauto">
            <table border="1" cellspacing="0" class="tableBorder assistrecordTable" :style="tableStyle">
                <thead>
                    <tr class="h40 baf7fafa">
                        <td class="textc itemTitle" colspan="6" width="850px">
                            凭证科目/研发项目费用
                        </td>
                        <td class="textc itemTitle" v-for="(row, index) in tableData.title" :key="index">
                            {{ row.code }}</td>
                    </tr>
                    <tr class="h70 baf7fafa">
                        <td class="textc itemTitle w150">
                            日期
                        </td>
                        <td class="textc itemTitle w100">
                            应出勤数
                        </td>
                        <td class="textc itemTitle w100">
                            实际出勤
                        </td>
                        <td class="textc itemTitle w100">
                            科技人员数
                        </td>
                        <td class="textc itemTitle w200">
                            人月工时
                        </td>
                        <td class="textc itemTitle w200">
                            研发工时总数（小时）
                        </td>
                        <td class="textc itemTitle" v-for="(row, index) in tableData.title" :key="index">
                            {{ row.name }}</td>
                    </tr>
                    <tr class="baf7fafa" v-if="tableData.list">
                        <td class="w150"></td>
                        <td class="w100"></td>
                        <td class="w100"></td>
                        <td class="w100"></td>
                        <td class="w200"></td>
                        <td class="w200"></td>
                        <td class="itemTitle textc h40" v-for="(row, index) in tableData.title" :key="index">{{
                row.startDay + " 至 " + row.endDay }}</td>
                    </tr>
                </thead>
                <tbody v-if="tableData.list" class="overflowHide" :style="{ 'max-height': tableHeight + 'px' }">
                    <tr class="h40" v-for="(row, index) in tableData.list" :key="index">
                        <td class="textc itemContent w150" v-if="!row.isPercentage">{{ row.day }}</td>
                        <td class="textc itemContent w100" v-if="!row.isPercentage">{{ row.workDay }}</td>
                        <td class="textc itemContent w100" v-if="!row.isPercentage">{{ row.realWorkDay }}</td>
                        <td class="textc itemContent w100" v-if="!row.isPercentage">{{ row.userNum }}</td>
                        <td class="textc itemContent w200" v-if="!row.isPercentage">{{ row.workHour | setHour }}</td>
                        <td class="textc itemContent w200" v-if="!row.isPercentage">{{ row.joinHour | setHour }}</td>
                        <td class="textc" colspan="6" width="850px" v-if="row.isPercentage">
                            项目工时投入占比
                        </td>
                        <td class="textc itemContent" v-for="(hour, key) in row.data" :key="key">
                            <span v-if="!row.isPercentage">{{ hour | setHour }}</span>
                            <span v-else>{{ hour | setHour }}%</span>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr class="h200 textc">
                        <td colspan="3">暂无数据</td>
                    </tr>
                </tbody>
                <tfoot v-if="tableData.total">
                    <tr class="h40 fw600 baf7fafa">
                        <td class="textc itemContent w150">合 计</td>
                        <td class="textc itemContent w100">{{ tableData.total.workDay }}</td>
                        <td class="textc itemContent w100">{{ tableData.total.realWorkDay }}</td>
                        <td class="textc itemContent w100"></td>
                        <td class="textc itemContent w200">{{ tableData.total.workHour | setHour }}</td>
                        <td class="textc itemContent w200">{{ tableData.total.joinHour | setHour }}</td>
                        <td class="textc itemContent" v-for="(money, key) in tableData.total.data" :key="key + 'total'">
                            {{ money | setHour }}
                        </td>
                    </tr>
                    <tr class="h40 fw600 baf7fafa">
                        <td class="textc itemContent" colspan="6" width="850px">合计项目用时：人·月</td>
                        <td class="textc itemContent" v-for="(money, key) in tableData.totalProject.data"
                            :key="key + 'total'">
                            <span>{{ money | setHour }}</span>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="showModel" :destroy-on-close="true" width="800px" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="130px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="年份" prop="year">
                            <el-input placeholder="请输入年份" class="suffixInput" v-model="formData.year" type="number">
                                <template slot="append">年</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="月份" prop="month">
                            <el-input placeholder="请输入月份" class="suffixInput" v-model="formData.month" type="number">
                                <template slot="append">月</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="摘要" prop="remark">
                            <el-input v-model="formData.remark" placeholder="请输入摘要"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="科目" prop="subject">
                            <el-input placeholder="请输入科目" v-model="formData.subject" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="凭证号" prop="voucherCode">
                            <el-input v-model="formData.voucherCode" placeholder="请输入凭证号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="凭证金额" prop="voucherHour">
                            <el-input placeholder="请输入凭证金额" class="suffixInput" v-model="formData.voucherHour"
                                type="number">
                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="应发工资总额" prop="wages">
                            <el-input placeholder="请输入凭证金额" class="suffixInput" v-model="formData.wages" type="number">
                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="计入研发费金额" prop="researchHour">
                            <el-input placeholder="请输入计入研发费金额" class="suffixInput" v-model="formData.researchHour"
                                type="number">
                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate" :loading="loadingBtn">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { setProjectMoneyUser, getProjectMoneyWorkTime } from "@/api/projectMoney";
export default {
    name: "assistrecordTable13",
    props: {
        checkedCompany: {
            require: true,
            type: Object,
            default: () => {
                return {}
            }
        },
        yearValue: {
            require: true,
            type: String,
            default: "",
        }
    },
    data() {
        return {
            //列表配置
            loading: false, //表格加载
            showModel: false,

            tableData: {
                title: []
            },
            //模态框配置
            modelTitle: "",
            formData: {
                puId: "",
                dataType: "",//数据类型（1：工资计算，2：社保，3：公积金，4：劳务费）
                year: "",//年份
                month: "",//月份
                remark: "",//摘要
                researchHour: "",//	计入研发费金额
                subject: "",//科目
                voucherCode: "",//凭证号
                voucherHour: "",//凭证金额
                wages: "",//应发工资总额
            },
            userInfo: this.$store.state.loginRoot.userInfo,
            rules: {
                puId: {
                    required: true,
                    trigger: "change",
                    message: "请输入企业员",
                },
                dataType: {
                    required: true,
                    trigger: "change",
                    message: "请输入类型",
                },
                yearValue: {
                    required: true,
                    trigger: "blur",
                    message: "请输入年份",
                },
                month: {
                    required: true,
                    trigger: "blur",
                    message: "请输入月份",
                },
                remark: {
                    required: true,
                    trigger: "blur",
                    message: "请输入摘要",
                },
                researchHour: {
                    required: true,
                    trigger: "blur",
                    message: "请输入计入研发费金额",
                },
                subject: {
                    required: true,
                    trigger: "blur",
                    message: "请输入科目",
                },
                voucherCode: {
                    required: true,
                    trigger: "blur",
                    message: "请输入凭证号",
                },
                voucherHour: {
                    required: true,
                    trigger: "blur",
                    message: "请输入凭证金额",
                },
                wages: {
                    required: true,
                    trigger: "blur",
                    message: "请输入应发工资总额",
                },
            },
            formType: "",
            updateId: 0,
            loadingBtn: false,
            tableHeight: 0,
            tableStyle: {},
        };
    },
    filters: {
        setHour(hour) {
            return (hour ? hour : 0).toFixed(2)
        }
    },
    watch: {
        checkedCompany: {
            handler(row) {
                //获取年份
                this.getList();
            }
        },
        yearValue: {
            handler(row) {
                //获取年份
                this.getList();
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {
        this.tableHeight = $(".content").height() - 75 - (25 + 56 + 56 + 74) - (40 + 70 + 100);
    },
    methods: {
        filesSuccess() {
            this.searchFun();
            this.uploadFilesVisible = false;
        },
        hideModel() {
            this.uploadFilesVisible = false;
        },

        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        addUpdate() {
            //修改添加
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.formData,
                        pcId: this.checkedCompany.pcId
                    };
                    let message = "新增成功";
                    if (this.updateId) {
                        params.mdId = this.updateId;
                        message = "修改成功"
                    }
                    this.loadingBtn = true;
                    setProjectMoneyUser(params).then(res => {
                        this.loadingBtn = false;
                        if (res.code === 200) {
                            this.$message.success(message);


                            this.searchFun();
                            this.showModel = false;
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        this.loadingBtn = false;
                        if (err.code === 201) {
                            this.$message.error(err.message);
                        }
                    })
                }
            });
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                year: this.yearValue,
                totalByProject: true
            };
            if (!this.checkedCompany.pcId) {
                this.tableData = {};
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            try {
                this.loading = true;
                let result = await getProjectMoneyWorkTime(params);
                this.loading = false;
                const { data } = result;
                let tableData = [];
                for (let row of data.list) {
                    let data = {
                        ...row,
                    }
                    tableData.push(data);
                }
                this.$set(this.tableData, "list", tableData);
                // this.tableData.list = tableData;
                this.tableData.title = data.title ? data.title : [];
                if (this.tableData.title) {
                    let tableW = 1000 + (this.tableData.title.length * 200);
                    if (tableW > $(".view").width()) {
                        this.tableStyle = {
                            width: tableW + 'px'
                        }
                    } else {
                        this.tableStyle = {
                            width: 'auto'
                        };
                    }

                } else {
                    this.tableStyle = {
                        width: 'auto'
                    };
                }
                this.tableData.total = data.total[0];
                this.tableData.totalProject = data.total[1];
            } catch (error) {
               //console.log(error);
                this.loading = false;
                this.tableData = {};
            }
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增工费用";
            this.formType = "add";
            this.formData = {
                puId: "",
                dataType: "",//数据类型（1：工资计算，2：社保，3：公积金，4：劳务费）
                year: "",//年份
                month: "",//月份
                remark: "",//摘要
                researchHour: "",//	计入研发费金额
                subject: "",//科目
                voucherCode: "",//凭证号
                voucherHour: "",//凭证金额
                wages: "",//应发工资总额
            };
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(val) {
            this.showModel = true;
            this.modelTitle = "编辑工费用";
            this.formType = "update";
            this.updateId = val.mdId;
            this.formData = {
                puId: String(val.puId),
                dataType: String(val.dataType),//数据类型（1：工资计算，2：社保，3：公积金，4：劳务费）
                year: val.year,//年份
                month: val.month,//月份
                remark: val.remark,//摘要
                researchHour: val.researchHour,//	计入研发费金额
                subject: val.subject,//科目
                voucherCode: val.voucherCode,//凭证号
                voucherHour: val.voucherHour,//凭证金额
                wages: val.wages,//应发工资总额
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.assistrecordTable {
    border-color: #000;
    word-break: break-all;
    table-layout: fixed;

    thead tr,
    tbody tr,
    tfoot tr {
        display: table;
        width: 100%;
        table-layout: fixed;

        td {
            box-sizing: border-box;
        }
    }

    tbody {
        display: block;
    }

    .itemTitle {
        font-size: 14px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        font-size: 14px;
        padding: 5px 10px;
    }

}
</style>