<template>
    <div class="view">
        <el-tabs v-model="dataType" class="assistrecordTypeTab" @tab-click="getList()">
            <el-tab-pane :label="item.title" :name="String(item.id)" v-for="(item, index) in typeObj"
                :key="index"></el-tab-pane>
        </el-tabs>
        <moneyTable class="pt40" ref="moneyTable" :tableData="tableData" v-loading="loading"
            @delDataFun="delProjectMoney" @updateDataFun="updateData" @nullListHref="hrefFun('/costEnterTable3')" />
    </div>
</template>

<script>
import { getProjectMoneyUserCount, delProjectMoneyScale, setProjectMoneyScale } from "@/api/projectMoney";
import moneyTable from "./moneyTable";
export default {
    components: { moneyTable },
    name: "assistrecordTable7",
    props: {
        checkedCompany: {
            require: true,
            type: Object,
            default: () => {
                return {}
            }
        },
        yearValue: {
            require: true,
            type: String,
            default: "",
        }
    },
    data() {
        return {
            //列表配置
            loading: false, //表格加载
            tableData: {},
            //1：研发设备，2：研发仪器
            typeObj: [
                { id: "all", title: "全部" },
                { id: 1, title: "研发设备" },
                { id: 2, title: "研发仪器" },
            ],
            dataType: "all",
        };
    },
    filters: {
        setMoney(money) {
            return (money ? money : 0).toFixed(2)
        }
    },
    watch: {
        checkedCompany: {
            handler(row) {

                //获取年份
                this.getList();
            },
        },
        yearValue: {
            handler(row) {
                //获取年份
                this.getList();
            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        hrefFun(url) {
            if (this.yearValue == 'all') {
                this.$router.push(url);
            } else {
                this.$router.push(url + "?year=" + this.yearValue);
            }
        },
        /**@method 修改项目金额 */
        updateData(projectData) {
            let updateList = [];
            for (let row of projectData.data) {
                let query = {
                    dataType: projectData.row.dataType,
                    fileType: projectData.row.fileType,
                    month: projectData.row.month,
                    pcId: this.checkedCompany.pcId,
                    money: row.dataNumber,
                    year: this.yearValue,
                    pdId: row.pdId,
                };
                if (row.isYearEnd) {
                    query.isYearEnd = row.isYearEnd;
                }
                if (row.puId) {
                    query.puId = row.puId;
                }
                updateList.push(query);
            }
            let params = {
                updateList: updateList
            };
            setProjectMoneyScale(params).then(res => {
                if (res.code === 200) {
                    this.$message.success("编辑完成")
                    this.$refs.moneyTable.closeDialog();
                    this.getList();
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 重置项目金额 */
        delProjectMoney(row) {
            let params = {
                dataType: row.dataType,
                fileType: row.fileType,
                month: row.month,
                pcId: this.checkedCompany.pcId,
                year: this.yearValue,
            }
            if (row.isYearEnd) {
                params.isYearEnd = row.isYearEnd;
            }
            if (row.puId) {
                params.puId = row.puId;
            }
            delProjectMoneyScale(params).then(res => {
                if (res.code === 200) {
                    this.$message.success("重置预算完成");
                    this.getList();
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 获取列表 */
        async getList() {
            /**
            * 0：人工费用{1：工资计算，2：社保，3：公积金，4：劳务费}，
            * 1：直接投入{1：材料，2：燃料，3：动力，4：制造费，5：样品，6：试验费，7：设备费，8：租赁}，
            * 2：折旧费用{1：研发设备，2：研发仪器}，
            * 3：无形资产摊销{1：软件，2：专利，3：非专利}，
            * 4：新产品设计费{1：新产品，2：新工艺，3：新药临床，4：勘探开发技术的现场}，
            * 5：其他费用{1：差旅，2：办公用品，3：会议费，4：知识产权，5：技术服务费，6：医疗附加}，
            * 6：委外技术开发费{1：委外开发，2：境外开发}）
             */
            let params = {
                year: this.yearValue,
                fileType: 2,
            };
            if (this.dataType != 'all') {
                params.dataType = this.dataType
            }
            if (!this.checkedCompany.pcId) {
                this.tableData = {};
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            try {
                this.loading = true;
                let result = await getProjectMoneyUserCount(params);
                this.loading = false;
                const { data } = result;
                let tableData = [];
                for (let row of data.list) {
                    let data = {
                        ...row,
                    }
                    tableData.push(data);
                }
                let list = {};
                list.list = tableData;
                list.title = data.title;
                list.total = data.total[0];
                this.tableData = list;
               //console.log(this.tableData);
            } catch (error) {
               //console.log(error);
                this.loading = false;
                this.tableData = {};
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.w1001 {
    width: 1001px;
    min-width: 1001px;
}

.w120 {
    width: 120px;
    min-width: 120px;
}

.w80 {
    width: 80px;
    min-width: 80px;
}

.w200 {
    width: 200px;
    min-width: 200px;
}

.w150 {
    width: 150px;
    min-width: 150px;
}

.w100 {
    width: 100px;
    min-width: 100px;
}

.assistrecordTableContent {
    overflow-x: scroll;

    div {
        color: #000;
    }
}

.assistrecordTable {

    .assistrecordTableRow {
        align-items: center;
        position: relative;

        .itemTitle {
            font-size: 16px;
            min-height: 30px;
            overflow: hidden;
            border-bottom: 1px solid #000;
        }

        .specialTable {
            position: relative;
            border: 1px solid #000;
            overflow: hidden;
            height: 60px;

            .titleLeft {
                position: absolute;
                left: 5px;
                bottom: 0px;
            }

            .titleRight {
                position: absolute;
                right: 5px;
                top: 0px;
            }

            .titleCenter {
                position: absolute;
                right: 5px;
                bottom: 0px;
            }
        }

        .itemContent {
            min-height: 30px;

            div {
                font-size: 16px;
            }
        }
    }
}

.rightBorder {
    border-right: 1px solid #000;
}

.topBorder {
    border-top: 1px solid #000;
}

.leftBorder {
    border-left: 1px solid #000;
}

.bottomBorder {
    border-bottom: 1px solid #000;
}
</style>