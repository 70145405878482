import httpUtil from "@/utils/httpUtil";

/**设置项目人工费用 */
export const setProjectMoneyUser = (params) => httpUtil.post("/api/crmPc/project/setProjectMoneyUser", params)

/**设置项目数据费用 */
export const setProjectMoneyData = (params) => httpUtil.post("/api/crmPc/project/setProjectMoneyData", params)

/**获得项目人工费用列表 */
export const getProjectMoneyUserList = (params) => httpUtil.post("/api/crmPc/project/getProjectMoneyUserList", params)

/**获得项目数据费用列表 */
export const getProjectMoneyDataList = (params) => httpUtil.post("/api/crmPc/project/getProjectMoneyDataList", params)

/**删除项目人工费用 */
export const delProjectMoneyUser = (params) => httpUtil.post("/api/crmPc/project/delProjectMoneyUser", params)

/**删除项目数据费用 */
export const delProjectMoneyData = (params) => httpUtil.post("/api/crmPc/project/delProjectMoneyData", params)

/**获得项目人员列表 */
export function getProjectUserList(params) {
    return httpUtil.post("/api/crmPc/project/getProjectUserList", params)
}

/**获得计算项目费用数据 */
export const getProjectMoneyCountList = async params => httpUtil.post("/api/crmPc/project/getProjectMoneyCountList", params);
/**获得汇总计算数据 */
export const getProjectMoneyTotalList = async params => httpUtil.post("/api/crmPc/project/getProjectMoneyTotalList", params);
/**获得辅助账数据
    3: "人工",
    4: "社保",
    5: "公积金",
    6: "直接投入",
    7: "折旧费用",
    8: "其他费用",
    9: "委外费用",
    10: "工资计算",
    11: "社保计算",
 */
export const getProjectMoneyUserCount = async params => httpUtil.post("/api/crmPc/project/getProjectMoneyUserCount", params);
/**获得工时数据 */
export const getProjectMoneyWorkTime = async params => httpUtil.post("/api/crmPc/project/getProjectMoneyWorkTime", params);
/**研究开发费用情况归集表 */
export const getProjectMoneyDeduction = async params => httpUtil.post("/api/crmPc/project/getProjectMoneyDeduction", params);
/**导出税务辅助帐 */
export const getProjectMoneyTaxList = async params => httpUtil.post("/api/crmPc/project/getProjectMoneyTaxList", params);
/**导出高企辅助帐 */
export const outPutProjectMoneyExcel = async params => httpUtil.post("/api/crmPc/project/outPutProjectMoneyExcel", params);
/**设置项目人员出勤及工时 */
export const setProjectUserWorkTime = async params => httpUtil.post("/api/crmPc/project/setProjectUserWorkTime", params);
/**删除项目占比 */
export const delProjectMoneyScale = async params => httpUtil.post("/api/crmPc/project/delProjectMoneyScale", params);
/**设置金额 */
export const setProjectMoneyScale = async params => httpUtil.post("/api/crmPc/project/setProjectMoneyScale", params);