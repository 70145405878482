<template>
    <div class="view pa24">
        <div class="flex-a-b-c">
            <div class="selectCompany flex-a-c hover_pointer" @click="showCompanyList"><i
                    class="el-icon-office-building fs12 mr5"></i> <span class="fs13">{{ checkedCompany.companyName
                    }}</span>
                <i class="el-icon-caret-bottom  fs12 ml5"></i>
            </div>
            <div>
                <el-button @click="exportProjectMoneyTaxList" :loading="exportLoadingBtn" type="primary"
                    :disabled="!checkedCompany.pcId" icon="el-icon-document" class="wordBtn">导出税务自主研发辅助帐</el-button>
                <el-button @click="outPutProjectMoneyExcel" :loading="exportLoadingBtn2" type="primary"
                    :disabled="!checkedCompany.pcId" icon="el-icon-document" class="wordBtn">导出高企研发辅助帐</el-button>
            </div>
        </div>
        <el-tabs v-model="yearValue">
            <el-tab-pane :label="String(y) + '年'" :name="String(y)" v-for="(y, index) in yearData"
                :key="index"></el-tab-pane>
        </el-tabs>
        <el-tabs v-model="dataType" class="assistrecordTypeTab">
            <el-tab-pane :label="item.title" :name="String(item.id)" v-for="(item, index) in typeObj"
                :key="index"></el-tab-pane>
        </el-tabs>
        <assistrecordTable1 v-if="dataType == '1'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />
        <assistrecordTable2 v-if="dataType == '2'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />
        <assistrecordTable3 v-if="dataType == '3'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />
        <assistrecordTable4 v-if="dataType == '4'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />
        <assistrecordTable5 v-if="dataType == '5'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />

        <assistrecordTable6 v-if="dataType == '6'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />
        <assistrecordTable7 v-if="dataType == '7'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />

        <assistrecordTable8 v-if="dataType == '8'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />
        <assistrecordTable9 v-if="dataType == '9'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />
        <assistrecordTable10 v-if="dataType == '10'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />
        <assistrecordTable11 v-if="dataType == '11'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />
        <assistrecordTable12 v-if="dataType == '12'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />
        <assistrecordTable13 v-if="dataType == '13'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />
        <assistrecordTable14 v-if="dataType == '14'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />
        <assistrecordTable15 v-if="dataType == '15'" ref="moneyTable" :checkedCompany="checkedCompany"
            :yearValue="yearValue" />

        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>
    </div>
</template>

<script>
import assistrecordTable1 from "@/pages/cost/components/assistrecordTable1";
import assistrecordTable2 from "@/pages/cost/components/assistrecordTable2";
import assistrecordTable3 from "@/pages/cost/components/assistrecordTable3";
import assistrecordTable4 from "@/pages/cost/components/assistrecordTable4";
import assistrecordTable5 from "@/pages/cost/components/assistrecordTable5";
import assistrecordTable6 from "@/pages/cost/components/assistrecordTable6";
import assistrecordTable7 from "@/pages/cost/components/assistrecordTable7";
import assistrecordTable8 from "@/pages/cost/components/assistrecordTable8";
import assistrecordTable9 from "@/pages/cost/components/assistrecordTable9";
import assistrecordTable10 from "@/pages/cost/components/assistrecordTable10";
import assistrecordTable11 from "@/pages/cost/components/assistrecordTable11";
import assistrecordTable12 from "@/pages/cost/components/assistrecordTable12";
import assistrecordTable13 from "@/pages/cost/components/assistrecordTable13";
import assistrecordTable14 from "@/pages/cost/components/assistrecordTable14";
import assistrecordTable15 from "@/pages/cost/components/assistrecordTable15";
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
import { getProjectMoneyTaxList, outPutProjectMoneyExcel } from "@/api/projectMoney";
export default {
    name: "assistrecord",
    components: {
        assistrecordTable1,
        assistrecordTable2,
        assistrecordTable3,
        assistrecordTable4,
        assistrecordTable5,
        assistrecordTable6,
        assistrecordTable7,
        assistrecordTable8,
        assistrecordTable9,
        assistrecordTable10,
        assistrecordTable11,
        assistrecordTable12,
        assistrecordTable13,
        assistrecordTable14,
        assistrecordTable15,
        /**
            1: "研发项目",
            2: "汇总",
            3: "人工",
            4: "社保",
            5: "公积金",
            6: "直接投入",
            7: "折旧费用",

            8: "其他费用",
            9: "委外费用",
            10: "工资计算",
            11: "社保计算",
            12: "出勤及工时",
         */
        selectCompanyList
    },
    data() {
        return {
            //列表配置
            dataType: "1",
            typeObj: [
                { id: 1, title: "研发项目" },
                { id: 2, title: "汇总" },
                // { id: 3, title: "人工" },
                // { id: 4, title: "社保" },
                // { id: 5, title: "公积金" },
                { id: 15, title: "人工费用" },
                { id: 6, title: "直接投入" },
                { id: 7, title: "折旧费用" },
                { id: 8, title: "无形资产摊销" },
                { id: 9, title: "新产品设计" },
                { id: 10, title: "其他费用" },
                { id: 11, title: "委外技术开发费" },
                { id: 12, title: "出勤及工时" },
                { id: 13, title: "项目工时" },
                { id: 14, title: "工时分配" },
                // { id: 6, title: "直接投入" },
                // { id: 7, title: "折旧费用" },
                // { id: 15, title: "无形资产摊销" },
                // { id: 16, title: "新产品设计" },
                // { id: 8, title: "其他费用" },
                // { id: 9, title: "委外费用" },
                // { id: 10, title: "工资计算" },
                // { id: 11, title: "社保计算" },
                // { id: 12, title: "出勤及工时" },
                // { id: 13, title: "项目工时" },
                // { id: 14, title: "工时分配" },
            ],
            checkedCompany: {},
            yearData: [],
            yearValue: "",
            exportLoadingBtn: false,
            exportLoadingBtn2: false,
        };
    },
    created() {
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
        this.getYearData();
    },
    mounted() {
        if (!this.checkedCompany.pcId) {
            this.showCompanyList();
        }
    },
    methods: {
        /**@method 导出税务自主研发辅助帐 */
        exportProjectMoneyTaxList() {
            this.exportLoadingBtn = true;
            getProjectMoneyTaxList({
                pcId: this.checkedCompany.pcId,
                year: this.yearValue
            }).then(res => {
                this.exportLoadingBtn = false;
                if (res.data) {
                    this.$message.success("导出税务辅助帐中...");
                    let link = document.createElement('a');
                    link.href = '/api/crmPc/project/downloadWordFile?fileName=' + res.data;
                    link.click();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.exportLoadingBtn = false;
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 导出高企研发辅助帐 */
        outPutProjectMoneyExcel() {
            this.exportLoadingBtn2 = true;
            outPutProjectMoneyExcel({
                pcId: this.checkedCompany.pcId,
                year: this.yearValue
            }).then(res => {
                this.exportLoadingBtn2 = false;
                if (res.data) {
                    this.$message.success("导出高企辅助帐中...");
                    let link = document.createElement('a');
                    link.href = '/api/crmPc/project/downloadWordFile?fileName=' + res.data;
                    link.click();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.exportLoadingBtn2 = false;
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 获取最近五年 */
        getYearData() {
            this.yearData = [];
            let yearData = [];
            let F = new Date().getFullYear();
            for (let i = 0; i <= 4; i++) {
                yearData.push(F - i);
            }
            this.yearData = yearData;
            this.yearValue = String(this.yearData[0])
        },
        showCompanyList() {
            this.$refs.selectCompanyList.showModelFun();
        },
        selectCompany(row) {
            this.checkedCompany = row;
            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));
        }
    },
};
</script>
<style lang="scss">
.assistrecordTypeTab {
    .el-tabs__item {
        padding: 0 15px !important;
        width: auto !important;
        height: 56px !important;
        line-height: 56px !important;
        text-align: center !important;
    }
}
</style>
<style lang="scss" scoped>
.selectCompany {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;
    cursor: pointer;
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>