<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" @click="showAddModel" :disabled="!checkedCompany.pcId || true">导出出勤表</el-button> -->
            </div>
        </div>
        <div class="oyauto" v-loading="loading">
            <table border="1" cellspacing="0" class="tableBorder assistrecordTable" :style="tableStyle">
                <thead>
                    <tr class="h40 baf7fafa">
                        <td class="w150"></td>
                        <td class="w150"></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="textc itemTitle" v-for="(row, index) in tableData.title" :key="index">
                            {{ row.code }}</td>
                    </tr>
                    <tr class="baf7fafa h40" v-if="tableData.list && tableData.list.length > 0">
                        <td class="w150"></td>
                        <td class="w150"></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="itemTitle textc" v-for="(row, index) in tableData.title" :key="index">{{
            row.startDay + " 至 " + row.endDay }}</td>
                    </tr>
                    <tr class="baf7fafa h40">
                        <td class="w150"></td>
                        <td class="w150"></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="textc itemTitle" v-for="(row, index) in tableData.title" :key="index">
                            {{ row.name }}</td>
                    </tr>
                    <tr class="h70 baf7fafa">
                        <td class="textc itemTitle w150">
                            月份
                        </td>
                        <td class="textc itemTitle w150">
                            人员
                        </td>
                        <td class="textc itemTitle">
                            出勤天数
                        </td>
                        <td class="textc itemTitle">
                            出勤工时数
                        </td>
                        <td class="textc itemTitle">
                            参与研发工时(小时)
                        </td>
                        <td class="textc itemTitle" v-for="(row, index) in tableData.title" :key="index">项目工时</td>
                    </tr>
                </thead>
                <tbody v-if="tableData.list && tableData.list.length > 0" class="overflowHide"
                    :style="{ 'max-height': tableHeight + 'px' }">
                    <tr class="h40 curPointer" v-for="(row, index) in tableData.list" :key="index"
                        :class="{ 'fwbold': row.isTotal, 'baeee': row.isTotal, 'fs8': row.isTotal }"
                        @click="showModelFun(row)">
                        <td class="textc itemContent w150">
                            {{ row.month }}月
                        </td>
                        <td class="textc itemContent w150">{{ row.name }}</td>
                        <td class="textc itemContent">{{ row.workDay }}</td>
                        <td class="textc itemContent">{{ row.workHour | setHour }}</td>
                        <td class="textc itemContent">{{ row.joinHour | setHour }}</td>
                        <td class="textc itemContent" v-for="(money, key) in row.data" :key="key">
                            <el-tooltip v-if="row.scaleData" class="tooltipItem" effect="dark"
                                :content="row.scaleData[key] + '%'" placement="top">
                                <div>{{ money | setHour }}</div>
                            </el-tooltip>
                            <div v-else>{{ money | setHour }}</div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr class="h200 textc">
                        <td colspan="3">暂无数据</td>
                    </tr>
                </tbody>
                <tfoot v-if="tableData.total">
                    <tr class="h40 fw600 baf7fafa">
                        <td class="textc itemContent" colspan="4" width="300px">总 计</td>
                        <td class="textc itemContent">{{ tableData.total.workDay | setHour }}</td>
                        <td class="textc itemContent">{{ tableData.total.workHour | setHour }}</td>
                        <td class="textc itemContent">{{ tableData.total.joinHour | setHour }}</td>
                        <td class="textc itemContent" v-for="(money, key) in tableData.total.data" :key="key + 'total'">
                            {{
            money | setHour }}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="userDetails.name + '参与研发工时'"
            :visible.sync="showModel" :destroy-on-close="true" width="1000px" center>
            <div class="d-flex mb20">
                <div style="margin-left: auto;">
                    <el-button type="primary" @click="setUserData">编辑{{ userDetails.name }}</el-button>
                </div>
            </div>
            <table cellspacing="0" class="setAssistrecord w100p">
                <thead>
                    <tr>
                        <td class="w80">月份</td>
                        <td class="w180 textc">人员</td>
                        <td class="w180">出勤天数</td>
                        <td class="w150">出勤工时数</td>
                        <td class="w180">参与研发工时</td>
                        <td class="textc">出勤 - 研发工时差值</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in updateList" :key="index">
                        <td>
                            {{ row.year + "年 " + row.month }}月
                        </td>
                        <td class="textc">
                            {{ row.name }}
                        </td>
                        <td class="pr20">
                            <el-input placeholder="请输入出勤天数" class="suffixInput" v-model="row.workDay" type="number">
                                <template slot="append">天</template>
                            </el-input>
                        </td>
                        <td>
                            {{ row.workDay * 8 }} 小时
                        </td>
                        <td>
                            <el-input placeholder="请输入参与研发工时" class="suffixInput mr20" v-model="row.joinHour"
                                type="number">

                                <template slot="append">小时</template>
                            </el-input>
                        </td>
                        <td class="textc">
                            {{ Math.abs((row.workDay * 8) - row.joinHour) }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate" :loading="loadingBtn">提 交</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { setProjectUserWorkTime, getProjectMoneyWorkTime } from "@/api/projectMoney";
export default {
    name: "assistrecordTable12",
    props: {
        checkedCompany: {
            require: true,
            type: Object,
            default: () => {
                return {}
            }
        },
        yearValue: {
            require: true,
            type: String,
            default: "",
        }
    },
    data() {
        return {
            //列表配置
            loading: false, //表格加载
            showModel: false,
            userDetails: {},
            tableHeight: 0,
            tableData: {},
            //模态框配置
            updateList: [
                {
                    month: "",
                    pcId: "",
                    puId: "",
                    year: "",
                    name: "",
                    utId: "",
                    workHour: "",

                    workDay: "",
                    joinHour: "",
                }
            ],
            userInfo: this.$store.state.loginRoot.userInfo,
            rules: {},
            formType: "",
            updateId: 0,
            loadingBtn: false,
            tableStyle: {},
        };
    },
    filters: {
        setHour(hour) {
            return (hour).toFixed(2)
        }
    },
    watch: {
        checkedCompany: {
            handler(row) {

                //获取年份
                this.getList();
            }
        },
        yearValue: {
            handler(row) {
                //获取年份
                this.getList();
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {
        this.tableHeight = $(".content").height() - 75 - (25 + 56 + 56 + 74) - (40 + 70 + 100);
    },
    methods: {
        setUserData() {
            this.$router.push("/projectUser?name=" + this.userDetails.name)
        },
        showModelFun(row) {
            // this.formData = {
            //     month: data.month,
            //     pcId: "",
            //     puId: data.puId,
            //     year: this.yearValue,
            //     name: data.name,
            //     utId: data.utId,
            //     workHour: data.workHour,

            //     workDay: data.workDay,
            //     joinHour: data.joinHour,
            // };
            if (row.isTotal) {
                return;
            }
            this.updateList = [];
            for (let data of this.tableData.list) {
                if (data.name == row.name) {
                    this.updateList.push({
                        month: data.month,
                        pcId: this.checkedCompany.pcId,
                        puId: data.puId,
                        year: this.yearValue,
                        name: data.name,
                        utId: data.utId,
                        workHour: data.workHour,

                        workDay: data.workDay,
                        joinHour: data.joinHour,
                    });
                }
            }
            this.userDetails = row;
            this.showModel = true;
        },
        filesSuccess() {
            this.searchFun();
            this.uploadFilesVisible = false;
        },
        hideModel() {
            this.uploadFilesVisible = false;
        },

        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        addUpdate() {
            //修改添加
            let params = {
                updateList: this.updateList
            };
            let message = "新增成功";
            if (this.updateId) {
                params.mdId = this.updateId;
                message = "修改成功"
            }
            this.loadingBtn = true;
            setProjectUserWorkTime(params).then(res => {
                this.loadingBtn = false;
                if (res.code === 200) {
                    this.$message.success(message);
                    this.searchFun();
                    this.showModel = false;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.loadingBtn = false;
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                year: this.yearValue,
                totalByMonth: true
            };
            if (!this.checkedCompany.pcId) {
                this.tableData = {};
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            try {
                this.loading = true;
                let result = await getProjectMoneyWorkTime(params);
                this.loading = false;
                const { data } = result;
                let tableData = [];
                for (let row of data.list) {
                    let data = {
                        ...row,
                    }
                    tableData.push(data);
                }
                this.tableData.list = tableData;
                this.tableData.title = data.title;
                if (this.tableData.title) {
                    let tableW = 1000 + (this.tableData.title.length * 200);
                    if (tableW > $(".view").width()) {
                        this.tableStyle = {
                            width: tableW + 'px'
                        }
                    } else {
                        this.tableStyle = {
                            width: 'auto'
                        };
                    }

                } else {
                    this.tableStyle = {
                        width: 'auto'
                    };
                }
                this.tableData.total = data.total[0];
               //console.log(this.tableData);
            } catch (error) {
               //console.log(error);
                this.loading = false;
                this.tableData = {};
            }
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增人工费用";
            this.formType = "add";
            this.formData = {
                puId: "",
                dataType: "",//数据类型（1：工资计算，2：社保，3：公积金，4：劳务费）
                year: "",//年份
                month: "",//月份
                remark: "",//摘要
                researchHour: "",//	计入研发费金额
                subject: "",//科目
                voucherCode: "",//凭证号
                voucherHour: "",//凭证金额
                wages: "",//应发工资总额
            };
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(val) {
            this.showModel = true;
            this.modelTitle = "编辑人工费用";
            this.formType = "update";
            this.updateId = val.mdId;
            this.formData = {
                puId: String(val.puId),
                dataType: String(val.dataType),//数据类型（1：工资计算，2：社保，3：公积金，4：劳务费）
                year: val.year,//年份
                month: val.month,//月份
                remark: val.remark,//摘要
                researchHour: val.researchHour,//	计入研发费金额
                subject: val.subject,//科目
                voucherCode: val.voucherCode,//凭证号
                voucherHour: val.voucherHour,//凭证金额
                wages: val.wages,//应发工资总额
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.setAssistrecord tbody tr:hover {
    background: #eee;

}

.setAssistrecord tr {
    height: 50px;
}

.assistrecordTable {
    border-color: #000;
    word-break: break-all;
    table-layout: fixed;

    thead tr,
    tbody tr,
    tfoot tr {
        display: table;
        width: 100%;
        table-layout: fixed;

        td {
            box-sizing: border-box;
        }
    }

    tbody {
        display: block;
    }

    .itemTitle {
        font-size: 14px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        font-size: 14px;
        padding: 5px 10px;
    }

}
</style>